import React from "react";
import "./styles/css/index.css";
import { BsInstagram } from "react-icons/bs";
import { MdLocationPin, MdOutlineFileDownload } from "react-icons/md";
import { FaPhone, FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { motion } from "framer-motion";
function App() {
  // const supportNumber = "09059147588";
  const ManagerNumber = "09132586858";
  const whatsAppNumber = "+989132586858";

  return (
    <div className=" overflow-hidden bg-slate-900 h-screen flex justify-center items-start ">
      <div className=" h-full max-w-[500px] p-3 flex  justify-around items-center flex-col ">
        <motion.div
          initial={{ y: -300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.1 }}
          className="flex flex-col justify-center items-center"
        >
          <img src="./AryanaLogo1111.png" className="h-32 " alt="" />
          <h4 className="font-Shabnam  text-xl mt-2 text-lime-500">
            فروشگاه کــارا
          </h4>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.7 }}
          className="flex max-h-[490px] min-h-[30px] justify-start flex-col items-center w-full "
        >
          <img className="h-full object-cover" src="aryanaShop.png" alt="" />
        </motion.div>
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.7 }}
          className="py-2 px-3  ring-2 rounded-md ring-lime-600 shadow-lg hover:shadow-lime-500/30 hover:bg-lime-600 transition-all duration-200 hover:text-slate-900 text-lime-600 w-full"
        >
          <a
            href="https://kara.sabaol.ir/KaraApp.apk"
            className="h-full font-ShabnamBold justify-center flex w-full"
          >
            دانلود
            <MdOutlineFileDownload className="text-2xl mr-1 animate-pulse " />
          </a>
        </motion.button>
        <motion.div
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8, delay: 0.9 }}
          className="w-full mt-2"
        >
          <div className=" py-2 w-full  flex justify-evenly items-center">
            <a href={`tel:${ManagerNumber}`}>
              <span className="active:opacity-70  shadow-lg shadow-orange-500/40 h-10 w-10 flex justify-center items-center  rounded-full bg-orange-600">
                <FaPhone className="text-white text-xl" />
              </span>
            </a>
            <a target="_blank" href={`https://wa.me/${whatsAppNumber}`}>
              <span className="active:opacity-70  pl-[0.5px] shadow-lg shadow-green-500/40 h-10 w-10 flex justify-center items-center  rounded-full bg-green-600">
                <FaWhatsapp className="text-white text-3xl" />
              </span>
            </a>

            <a target="_blank" href="#">
              <span className="active:opacity-70 shadow-lg pr-[1px] shadow-fuchsia-500/40 h-10 w-10 flex justify-center items-center  rounded-full bg-fuchsia-600">
                <BsInstagram className="text-white text-2xl" />
              </span>
            </a>

            <a target="_blank" href="#">
              <span className="active:opacity-70 pt-0.5 pr-0.5 shadow-lg shadow-sky-500/40 h-10 w-10 flex justify-center items-center  rounded-full bg-sky-600">
                <FaTelegramPlane className="text-white text-2xl" />
              </span>
            </a>

            <a target="_blank" href="#">
              <span className="active:opacity-70  shadow-lg p-2 shadow-white/40 h-10 w-10 flex justify-center items-center  rounded-full bg-white">
                <img src="./rubika.png" alt="" />
              </span>
            </a>
          </div>
          <div className=" py-2 w-full  flex mt-2 justify-center items-center">
            <span className="flex justify-start w-full items-center">
              <span className="ml-2">
                <MdLocationPin className="text-white text-3xl shadow-cyan-500/40" />
              </span>
              <h4 className="text-white">یزد - خیابان تیمسار فلاحی</h4>
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default App;
